import React from "react"
import styled, { css } from "styled-components"

import { Logo } from "components/svg/Logo"
import { Link } from "gatsby"

import { COLOR_MAIN } from "style/colors"
import { CONTENT_SIZE } from "style/size"

import { HiddenText } from "./text/HiddenText"

import { Navbar } from "./nav/Navbar"
import { Hamburger } from "./nav/Hamburger"
import { MenuItems } from "./nav/MenuItems"

import { Social } from "./complete/Social"
import { Phone } from "./complete/Phone"
import { Copyright } from "./complete/Copyright"
import { DesktopButtons } from "./nav/DesktopButtons"

const BG = styled.div`
	flex: 1;
	background-color: ${COLOR_MAIN};
	display: flex;
	flex-direction: column;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 1000;
	@media screen and (min-width: 800px) {
		position: relative;
		height: auto;
		${props =>
			props.open &&
			css`
				position: sticky;
				height: 100vh;
			`}
	}
`

const Wrapper = styled.div`
	position: sticky; /* This could be relative if we only want sticky when menu is open, is a bit weird though */
	top: 0;
	z-index: 1000;
	width: 100%;
	max-width: ${CONTENT_SIZE}px;
	flex: 1;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	padding: 40px 24px;
	background-color: ${COLOR_MAIN};
	${props =>
		props.open &&
		css`
			position: sticky;
			top: 0;
			height: 100vh;
		`}/*@media screen and (min-width: 800px) {
		position: relative;
		flex-direction: row;
		position: relative;
		height: auto;
	}*/
`

/*
const NavbarWrapper = ({ forwardRef, open, children, ...rest }) => {
	return (
		<BG {...rest}>
			<Wrapper open={open} ref={forwardRef}>
				{children}
			</Wrapper>
		</BG>
	)
}*/

const LogoElement = ({ ...rest }) => {
	return (
		<Link to="/" {...rest}>
			<Logo width="85px" height="48px" color="white" />
			<HiddenText>Gå til forsiden</HiddenText>
		</Link>
	)
}

export const Header = () => {
	return (
		<Navbar
			outerWrapper={BG}
			wrapper={Wrapper}
			logo={LogoElement}
			burger={Hamburger}
			backgroundColor={COLOR_MAIN}
			desktopMenuElements={<DesktopButtons />}
			hideClosedDesktop
			mobileMenuElements={
				<>
					<Phone />
					<Social />
					<Copyright />
				</>
			}
		>
			<MenuItems />
		</Navbar>
	)
}
