import React from "react"
import styled from "styled-components"

import { ContentPortableText } from "components/blockContent/ContentPortableText"
import { Button } from "components/interactable/Button"
import { Cross } from "components/svg/Cross"
import { HiddenText } from "../text/HiddenText"
import { SmallText } from "../text/SmallText"
import { utility } from "../../style/style-utils"

const Wrapper = styled.div`
	width: 100%;
	padding: 20px;
	background-color: ${props => props.bgColor || "#FFFFFF55"};
	color: ${props => props.color || "black"};

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	span,
	p {
		margin: 0;
	}
`

const CrossButton = styled.button`
	${utility.button}
	padding: 12px;
`

export const Banner = ({
	text,
	color,
	bgColor,
	buttonText,
	buttonIsCross,
	onClose = () => {},
}) => {
	return (
		<Wrapper color={color} bgColor={bgColor}>
			<ContentPortableText blocks={text} />
			{buttonIsCross ? (
				<CrossButton onClick={onClose}>
					<HiddenText>{buttonText}</HiddenText>
					<Cross />
				</CrossButton>
			) : (
				<Button onClick={onClose}>
					<SmallText mb="0">{buttonText}</SmallText>
				</Button>
			)}
		</Wrapper>
	)
}
