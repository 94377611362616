import React from "react"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent"
import { graphql, useStaticQuery } from "gatsby"

import { ContentPortableText } from "components/blockContent/ContentPortableText"

const ContentWrapper = styled.div`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	span,
	p {
		margin: 0;
		font-size: 16px;
	}
`

export const CookieBanner = () => {
	const data = useStaticQuery(query)

	const { accept, deny, _rawText } = data?.sanitySiteSettings?.cookies || {}

	console.log("CookieBanner", { accept, deny, _rawText })

	return (
		<CookieConsent
			enableDeclineButton
			location="bottom"
			buttonText={accept || "Jeg godtar"}
			declineButtonText={deny || "Jeg godtar ikke"}
			cookieName="gatsby-gdpr-google-tagmanager"
			buttonStyle={{ fontFamily: "roboto", fontWeight: 700 }}
			declineButtonStyle={{ fontFamily: "roboto", fontWeight: 700 }}
		>
			<ContentWrapper>
				<ContentPortableText blocks={_rawText} />
			</ContentWrapper>
		</CookieConsent>
	)
}

const query = graphql`
	query GetCookieBanner {
		sanitySiteSettings {
			cookies {
				accept
				deny
				_rawText(resolveReferences: { maxDepth: 10 })
			}
		}
	}
`
