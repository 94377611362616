import React from "react"
import styled from "styled-components"
import { ErrorMessage } from "@hookform/error-message"

import { v4 as uuidv4 } from "uuid"

import { utility } from "style/style-utils"
//import { COLOR_WHITE } from "style/colors"

import { Text } from "components/text/Text"
import { ErrorText } from "components/text/ErrorText"

const Wrapper = styled.div`
	/*width: 100%;*/
	/*margin-bottom: 8px;*/
`

const InputContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 8px 16px;
	/*border-radius: 6px;*/
	border: 1px solid #dbdee7;
	border-radius: 6px;
	margin-bottom: ${props => props.margin || "0"};
	&:focus-within {
		/*outline: 2px solid black;
        outline-offset: 2px;*/
		background-color: #be530911;
		/*padding: 16px;*/
	}
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	@media screen and (min-width: 800px) {
		max-width: ${props => props.desktopMaxWidth || "360px"};
		margin-left: auto;
		margin-right: auto;
	}
`

const Input = styled(Text).attrs({ as: "input" })`
	color: inherit;
	line-height: initial;
	box-sizing: border-box;
	border: 0;
	background: transparent;
	width: 100%;
	margin: 0;
	padding: 0;
	font-weight: 600;
	&:focus {
		outline: none;
	}
	&::placeholder {
		font-weight: 400;
		color: inherit;
	}
	@media screen and (min-width: 800px) {
		line-height: initial;
	}
`

const Label = styled.label`
	${props => props.hideVisibly && utility.visuallyHidden}
`

export const TextInput = ({
	id = uuidv4(),
	name,
	label,
	hideLabel,
	type = "text",
	wrapperProps,
	register,
	validation,
	errors = {},
	...rest
}) => {
	//const [uniqueId] = useState(uuidv4()) //this should, in theory, run on initial mount only
	return (
		<Wrapper>
			<InputContainer {...wrapperProps}>
				<Label htmlFor={id} hideVisibly={hideLabel}>
					{label}
				</Label>
				<Input
					id={id}
					name={name}
					type={type}
					ref={validation ? register : register(validation)}
					aria-invalid={errors[name] !== undefined}
					aria-errormessage={`${id}Error`}
					{...rest}
				/>
			</InputContainer>
			<ErrorMessage
				name={name}
				errors={errors}
				render={({ message }) => (
					<ErrorText id={`${id}Error`}>{message}</ErrorText>
				)}
			/>
		</Wrapper>
	)
}
