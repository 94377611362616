import React from "react"

import { COLOR_MAIN } from "style/colors"

export const Cross = ({ color = COLOR_MAIN, ...rest }) => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M12.4239 10.5002L20.6009 2.32276C21.133 1.79085 21.133 0.930835 20.6009 0.398929C20.069 -0.132976 19.209 -0.132976 18.6771 0.398929L10.4999 8.57633L2.3229 0.398929C1.79075 -0.132976 0.931003 -0.132976 0.399108 0.398929C-0.133036 0.930835 -0.133036 1.79085 0.399108 2.32276L8.57609 10.5002L0.399108 18.6776C-0.133036 19.2095 -0.133036 20.0695 0.399108 20.6014C0.664183 20.8667 1.01272 21 1.361 21C1.70929 21 2.05757 20.8667 2.3229 20.6014L10.4999 12.424L18.6771 20.6014C18.9424 20.8667 19.2907 21 19.639 21C19.9873 21 20.3356 20.8667 20.6009 20.6014C21.133 20.0695 21.133 19.2095 20.6009 18.6776L12.4239 10.5002Z"
				fill={color}
			/>
		</svg>
	)
}
