import { graphql, Link as BaseLink, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

import { STYLE } from "style"
import { COLOR_MAIN, COLOR_WHITE } from "style/colors"

import { Phone } from "./complete/Phone"
import { Copyright } from "./complete/Copyright"
import { Social } from "./complete/Social"

import { Title } from "./text/Title"

import { Button } from "./interactable/Button"

import { LogoText as BaseLogoText } from "./svg/LogoText"
import { CONTENT_SIZE } from "../style/size"
import { SmallText } from "./text/SmallText"
import { Bold } from "./text/Bold"
import { getLinkPropsFromReference } from "../linkFunctions"

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const Triangle = styled.div`
	position: relative;
	bottom: -1px; /* Needs this to be flush with the below, for some reason */
	height: 100px;
	width: 100%;
	background-color: ${COLOR_MAIN};
	clip-path: polygon(0 0, 100% 100%, 0 100%);
	@media screen and (min-width: 800px) {
		height: 200px;
	}
`

const Content = styled.div`
	background-color: ${COLOR_MAIN};
	color: ${COLOR_WHITE};
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 26px;
	padding-top: 0;
	> * {
		max-width: ${CONTENT_SIZE}px;
	}
`

const Link = styled(BaseLink)`
	${STYLE.TEXT.MOBILE.FOOTER_LINK}
	color: ${COLOR_WHITE};
	@media screen and (min-width: 800px) {
		${STYLE.TEXT.DESKTOP.FOOTER_LINK}
	}
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 80px;
	> * {
		min-height: 64px;
		min-width: 200px;
		margin: 6px;
	}
`

const Bottom = styled.div`
	@media screen and (min-width: 800px) {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
`

const Links = styled.div`
	margin-bottom: 80px;
	display: flex;
	flex-direction: column;
	> *:first-child {
		margin-bottom: 16px;
	}
`

const LogoText = styled(BaseLogoText)`
	margin-bottom: 80px;
	max-width: 100%;
	@media screen and (min-width: 800px) {
		height: 100px;
		margin-bottom: 0;
	}
`

const FooterTitle = styled(SmallText).attrs({ as: "h3" })`
	font-weight: bold;
	@media screen and (min-width: 800px) {
		font-weight: bold;
	}
`

const PhoneOnly = styled.div`
	@media screen and (min-width: 800px) {
		display: none;
	}
`

const OpeningTimes = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
	@media screen and (min-width: 800px) {
	text-align: left;
	}

`

const DesktopOnly = styled.div`
	flex: 1;
	flex-direction: row;
	justify-content: space-between;

	display: none;
	@media screen and (min-width: 800px) {
		display: flex;
	}
	> * {
		margin-left: 40px;
	}
`

const Col = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
`

const Item = styled(SmallText).attrs({ as: "li" })``

export const Footer = () => {
	const {
		description,
		email,
		footer_order_title,
		orgNumber,
		phone,
		postPlace,
		address,
		opening_hours,
		_rawAboutPage: aboutPage,
		_rawContactPage: contactPage,
		_rawOrderPage: orderPage,
	} = useStaticQuery(query)?.sanitySiteSettings

	console.log({
		description,
		email,
		footer_order_title,
		orgNumber,
		phone,
		postPlace,
		address,
		opening_hours,
		aboutPage,
		contactPage,
		orderPage,
	})

	const locationLink = (
		<Link {...getLinkPropsFromReference(contactPage)}>
			Her finner du oss
		</Link>
	)

	return (
		<Wrapper>
			<Triangle />
			<Content>
				<Title as="p">{footer_order_title}</Title>
				<Row>
					<Button {...getLinkPropsFromReference(orderPage)}>
						Bestill visning
					</Button>
				</Row>
				<Bottom>
					<LogoText color="white" />
					<DesktopOnly>
						<Col>
							<FooterTitle>Adresse</FooterTitle>
							<SmallText>
								{address}
								<br />
								{postPlace}
							</SmallText>
							<SmallText>Org. Nr. {orgNumber}</SmallText>
							{locationLink}
						</Col>
						<Col>
							<FooterTitle>Telefon</FooterTitle>
							<SmallText><a href={"tel:" + phone} style={{ color: "#fff", textDecoration: "none" }} rel="noopener noreferrer">{phone}</a></SmallText>
							<FooterTitle>E-post</FooterTitle>
							<SmallText><a href={"mailto:" + email} style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">{email}</a></SmallText>
						</Col>
						<Col>
							<OpeningTimes>
								<FooterTitle>Åpningstider</FooterTitle>
								{opening_hours.map(
									({ _key, title, opening, closing }) => {
										return (
											<Item key={_key}>
												<Bold>{title}</Bold> {opening} -
												{closing}
											</Item>
										)
									}
								)}
							</OpeningTimes>
						</Col>

					</DesktopOnly>
					<PhoneOnly>
						<Phone margin="0" mb="30px" />
						<Links>
							{locationLink}
						</Links>
						<OpeningTimes>
							<FooterTitle>Åpningstider</FooterTitle>
							{opening_hours.map(
								({ _key, title, opening, closing }) => {
									return (
										<Item key={_key}>
											<Bold>{title}</Bold> {opening} -
											{closing}
										</Item>
									)
								}
							)}
						</OpeningTimes>
					</PhoneOnly>
				</Bottom>
				<Social />
				<Copyright />
			</Content>
		</Wrapper>
	)
}

const query = graphql`
	query GetSettings {
		sanitySiteSettings {
			footer_order_title
			phone
			postPlace
			orgNumber
			email
			address
			opening_hours {
				_key
				title
				opening
				closing
			}
			_rawAboutPage(resolveReferences: { maxDepth: 10 })
			_rawContactPage(resolveReferences: { maxDepth: 10 })
			_rawOrderPage(resolveReferences: { maxDepth: 10 })
		}
	}
`
