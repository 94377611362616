import React from "react"
import styled from "styled-components"

import { COLOR_MAIN_LIGHT, COLOR_WHITE } from "style/colors"

const SVG = styled.svg``
export const ArrowIcon = ({
	color = COLOR_MAIN_LIGHT,
	arrowColor = COLOR_WHITE,
	...rest
}) => {
	return (
		<SVG
			width="57"
			height="56"
			viewBox="0 0 57 56"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<rect width="57" height="56" rx="28" />
			<path
				d="M32.4103 22.5084C32.874 22.0528 33.626 22.0528 34.0897 22.5084L38.839 27.1744L38.8397 27.1751C38.9536 27.2869 39.0394 27.4158 39.0974 27.5534C39.1554 27.691 39.1875 27.8418 39.1875 28C39.1875 28.1576 39.1557 28.3078 39.0981 28.445C39.0695 28.5131 39.0341 28.5791 38.9918 28.642C38.9483 28.7066 38.8976 28.768 38.8397 28.825L38.839 28.8257L34.0897 33.4916C33.626 33.9472 32.874 33.9472 32.4103 33.4916C31.9466 33.036 31.9466 32.2973 32.4103 31.8417L35.1331 29.1667H19C18.3442 29.1667 17.8125 28.6443 17.8125 28C17.8125 27.3557 18.3442 26.8334 19 26.8334H35.1331L32.4103 24.1583C31.9466 23.7027 31.9466 22.964 32.4103 22.5084Z"
				fill={arrowColor}
			/>
		</SVG>
	)
}
