import styled from "styled-components"

import { STYLE } from "style"
import { COLOR_WHITE } from "style/colors"

export const PopTitle = styled.h2`
	${STYLE.TEXT.MOBILE.POP_TITLE}
	color: ${COLOR_WHITE};
	@media screen and (min-width: 800px) {
		${STYLE.TEXT.DESKTOP.POP_TITLE}
	}
`
