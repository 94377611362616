import React from "react"
import styled from "styled-components"

import { COLOR_MAIN } from "style/colors"

const SVG = styled.svg``

export const LogoText = ({ color = COLOR_MAIN, ...rest }) => {
	return (
		<SVG
			width="261"
			height="162"
			viewBox="0 0 261 162"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path d="M11.528 160.295C11.528 160.934 11.1544 161.361 10.4071 161.627C9.92675 161.841 9.28626 161.947 8.53903 161.947H2.6679V159.548H7.41818V157.789H5.33659C4.64273 157.789 4.05562 157.683 3.46851 157.416C2.6679 157.149 2.34766 156.67 2.34766 156.083V152.778C2.34766 151.659 3.36176 151.126 5.38997 151.126H11.1544V153.578H6.40407V155.39H8.48566C10.4605 155.39 11.528 155.923 11.528 157.043V160.295Z" />
			<path d="M22.2571 162H17.9871C17.7736 161.627 17.5068 160.987 17.0798 160.188C17.0798 160.134 16.9197 159.815 16.7595 159.175V162H12.7031V151.179H16.7595V153.631C16.8129 153.364 16.973 153.045 17.1865 152.565C17.1865 152.511 17.2933 152.405 17.4534 152.138L18.0405 151.232H22.3104L18.681 156.19L22.2571 162Z" />
			<path d="M32.237 160.294C32.237 161.414 31.2229 161.947 29.1946 161.947H26.099C25.672 161.947 25.2983 161.894 24.8714 161.787C24.391 161.627 24.0174 161.52 23.6971 161.254C23.2701 160.987 23.0566 160.667 23.0566 160.241V151.072H27.1664V159.495H28.1805V151.072H32.237V160.294Z" />
			<path d="M39.6556 162H33.5176V151.126H37.6274V159.601H39.6556V162Z" />
			<path d="M47.0755 162H40.9375V151.126H44.9939V159.601H47.0755V162Z" />
			<path d="M57.2169 162H48.4102V151.126H57.2169V153.578H52.4666V155.39H55.8825V157.789H52.4666V159.601H57.2169V162Z" />
			<path d="M63.566 155.39V153.578H62.5519V155.39H63.566ZM67.996 162H63.8328L63.4592 161.254C63.4058 161.094 63.2991 160.881 63.1924 160.667C63.139 160.561 62.9255 159.974 62.6052 158.855V162.053H58.5488V151.232H64.6868C65.5942 151.232 66.2347 151.285 66.6617 151.499C67.3555 151.712 67.7291 152.192 67.7291 152.938V156.243C67.7291 156.723 67.4623 157.043 66.9285 157.256C66.5015 157.416 66.0212 157.522 65.4874 157.522L67.996 162Z" />
			<path d="M78.1393 160.294C78.1393 161.414 77.1252 161.947 75.097 161.947H72.0013C71.5743 161.947 71.2007 161.894 70.7737 161.787C70.2933 161.627 69.9197 161.52 69.5995 161.254C69.1725 160.987 68.959 160.667 68.959 160.241V151.072H73.0154V159.495H74.0295V151.072H78.0859V160.294" />
			<path d="M84.6512 159.601V153.578H83.6371V159.601H84.6512ZM88.7077 160.294C88.7077 160.667 88.4942 160.987 88.0672 161.307C87.6936 161.574 87.3733 161.68 86.8929 161.84C86.5193 161.894 86.0923 162 85.6653 162H79.5273V151.179H85.6653C87.6402 151.179 88.7077 151.765 88.7077 152.831V160.294Z" />
			<path d="M103.758 160.294C103.758 160.934 103.384 161.361 102.637 161.627C102.157 161.84 101.516 161.947 100.715 161.947H94.8977V159.548H99.7014V157.789H97.6198C96.9259 157.789 96.2854 157.682 95.7517 157.416C95.0045 157.149 94.6309 156.67 94.6309 156.083V152.778C94.6309 151.659 95.645 151.126 97.6732 151.126H103.438V153.578H98.6873V155.39H100.769C102.797 155.39 103.811 155.923 103.811 157.043V160.294" />
			<path d="M109.949 155.39V153.578H108.935V155.39H109.949ZM114.059 156.083C114.059 156.669 113.685 157.096 112.938 157.416C112.351 157.682 111.71 157.789 111.07 157.789H108.988V162H104.932V151.179H111.016C111.924 151.179 112.564 151.285 112.991 151.445C113.685 151.712 114.059 152.192 114.059 152.885V156.083Z" />
			<path d="M120.304 159.601V153.578H119.29V159.601H120.304ZM124.413 160.294C124.413 160.881 124.04 161.361 123.293 161.627C122.759 161.84 122.118 161.947 121.424 161.947H118.329C117.635 161.947 116.994 161.84 116.461 161.574C115.66 161.254 115.34 160.827 115.34 160.241V152.778C115.34 151.659 116.354 151.126 118.382 151.126H121.478C123.453 151.126 124.467 151.712 124.467 152.778V160.294" />
			<path d="M130.66 155.39V153.578H129.7V155.39H130.66ZM135.09 162H130.927L130.554 161.254C130.5 161.094 130.393 160.881 130.287 160.667C130.233 160.561 130.02 159.974 129.7 158.855V162.053H125.59V151.232H131.728C132.635 151.232 133.329 151.285 133.756 151.499C134.45 151.712 134.77 152.192 134.77 152.938V156.243C134.77 156.723 134.503 157.043 133.97 157.256C133.543 157.416 133.062 157.522 132.528 157.522L135.09 162Z" />
			<path d="M145.122 153.578H142.613V162H138.503V153.578H135.941V151.126H145.122V153.578Z" />
			<path d="M159.962 160.294C159.962 160.934 159.588 161.361 158.841 161.627C158.307 161.84 157.666 161.947 156.919 161.947H151.101V159.548H155.852V157.789H153.77C153.076 157.789 152.436 157.682 151.902 157.416C151.155 157.149 150.781 156.67 150.781 156.083V152.778C150.781 151.659 151.795 151.126 153.824 151.126H159.588V153.578H154.838V155.39H156.919C158.947 155.39 159.962 155.923 159.962 157.043V160.294Z" />
			<path d="M169.996 162H161.189V151.126H169.996V153.578H165.246V155.39H168.662V157.789H165.246V159.601H169.996V162Z" />
			<path d="M180.831 162H176.988L176.507 160.934C176.401 160.774 176.294 160.508 176.134 160.134C175.813 159.495 175.44 158.642 174.959 157.576V162H171.33V151.179H175.173L175.653 152.192C175.76 152.352 175.867 152.618 176.027 152.938C176.347 153.524 176.721 154.377 177.255 155.497V151.232H180.884V162" />
			<path d="M191.078 153.578H188.57V162H184.513V153.578H181.951V151.126H191.078V153.578Z" />
			<path d="M201.111 162H192.305V151.126H201.111V153.578H196.361V155.39H199.777V157.789H196.361V159.601H201.111V162Z" />
			<path d="M207.463 155.39V153.578H206.449V155.39H207.463ZM211.893 162H207.677L207.356 161.254C207.303 161.094 207.196 160.881 207.036 160.667C206.983 160.561 206.769 159.974 206.449 158.855V162.053H202.393V151.232H208.531C209.438 151.232 210.078 151.285 210.505 151.499C211.199 151.712 211.573 152.192 211.573 152.938V156.243C211.573 156.723 211.306 157.043 210.772 157.256C210.345 157.416 209.865 157.522 209.331 157.522L211.893 162Z" />
			<path d="M12.8631 106.454L0 145.635H212.695L242.638 54.2665H76.2179L82.4093 35.4492H248.882L260.411 0H47.7163L19.0011 87.4768L166.954 87.5301L160.816 106.401L12.8631 106.454Z" />
		</SVG>
	)
}
