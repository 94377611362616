import React from "react"
import styled from "styled-components"

import { STYLE } from "style"

const Wrapper = styled.p`
	${STYLE.TEXT.MOBILE.COPYRIGHT}
	margin: 0;
	margin-top: 24px;
	/*
	This will make dynamic margin-top
	&:before {
		content: "";
		display: block;
		height: 2vh;
		max-height: 24px;
	}*/
`

export const Copyright = ({ ...rest }) => {
	return <Wrapper {...rest}>© 2021. All rights reserved</Wrapper>
}
