import React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"

import { COLOR_MAIN, COLOR_WHITE } from "style/colors"

import { PopTitle } from "components/text/PopTitle"
import { SmallText } from "components/text/SmallText"
import { Button } from "components/interactable/Button"
import { ContentPortableText } from "components/blockContent/ContentPortableText"
import { TitleSmall } from "../../text/TitleSmall"
import { Text } from "../../text/Text"
import { Bold } from "../../text/Bold"
import { getLinkPropsFromRawData } from "../../../linkFunctions"

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: ${COLOR_MAIN};
	color: ${COLOR_WHITE};
	border-radius: 6px;
	padding: 24px 40px;
	/*margin-bottom: 40px;*/
	@media screen and (min-width: 800px) {
		flex-direction: row;
	}
	> ${PopTitle},${SmallText} {
		color: ${COLOR_WHITE};
		text-align: center;
		margin: 0;
		margin-bottom: 8px;
		@media screen and (min-width: 800px) {
			text-align: left;
			margin-bottom: 0;
		}
	}
	> ${PopTitle}, ${Button} {
		flex: 3;
	}
	${SmallText} {
		flex: 5;
		@media screen and (min-width: 800px) {
			margin-left: 20px;
			margin-right: 20px;
		}
		@media screen and (min-width: 1200px) {
			margin-left: 60px;
			margin-right: 60px;
		}
	}
`

const BlockRenderer = props => {
	const { style = "normal" } = props.node

	if (style === "normal") {
		return <SmallText>{props.children}</SmallText>
	}

	if (style === "h2") {
		return (
			<TitleSmall as="h2" color={COLOR_WHITE}>
				{props.children}
			</TitleSmall>
		)
	}

	if (style === "h3") {
		return (
			<Text as="h3" color={COLOR_WHITE}>
				<Bold>{props.children}</Bold>
			</Text>
		)
	}

	if (style === "blockquote") {
		return <SmallText color={COLOR_WHITE}>- {props.children}</SmallText>
	}

	// Fall back to default handling
	return BlockContent.defaultSerializers.types.block(props)
}

/**
 * PopBox component. Displays as a row with a background color
 * @param {*} param0
 * @returns
 */

export const Box = ({ title, text, buttonText, buttonTo, ...rest }) => {
	console.log({ title, text, buttonText, buttonTo })
	return (
		<Wrapper {...rest}>
			<PopTitle>{title}</PopTitle>
			<ContentPortableText
				blocks={text}
				serializers={{ types: { block: BlockRenderer } }}
			/>
			<Button
				{...getLinkPropsFromRawData(buttonTo)}
				nowrap
				width="100%"
				maxWidth="240px"
			>
				{buttonText}
			</Button>
		</Wrapper>
	)
}
