import React from "react"
import styled from "styled-components"

import { COLOR_WHITE } from "style/colors"

const SVG = styled.svg``
export const Instagram = ({ color = COLOR_WHITE, ...rest }) => {
	return (
		<SVG
			width="41"
			height="38"
			viewBox="0 0 41 38"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path d="M12.7588 3.43799H27.423C32.4032 3.43799 36.4549 7.26869 36.4547 11.977V25.8415C36.4547 30.5498 32.4032 34.3805 27.423 34.3805H12.7588C7.77888 34.3805 3.72742 30.55 3.72742 25.8415V11.977C3.72742 7.26869 7.77888 3.43799 12.7588 3.43799ZM27.4232 31.6351C30.8022 31.6351 33.5512 29.0362 33.5512 25.8415H33.5511V11.977C33.5511 8.7825 30.802 6.18337 27.423 6.18337H12.7589C9.38008 6.18337 6.63122 8.7825 6.63122 11.977V25.8415C6.63122 29.0362 9.38008 31.6353 12.7589 31.6351H27.4232ZM11.5196 18.9095C11.5196 14.4407 15.3647 10.8053 20.091 10.8053C24.8174 10.8053 28.6624 14.4407 28.6624 18.9095C28.6624 23.378 24.8174 27.0133 20.091 27.0133C15.3647 27.0133 11.5196 23.378 11.5196 18.9095ZM14.471 18.9093C14.471 21.8391 16.9923 24.2226 20.091 24.2226C23.1898 24.2226 25.7111 21.8391 25.7111 18.9093C25.7111 15.9792 23.19 13.5956 20.091 13.5956C16.9921 13.5956 14.471 15.9792 14.471 18.9093Z" />
		</SVG>
	)
}
