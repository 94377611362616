import React from "react"
import styled from "styled-components"

const Wrapper = styled.a`
	text-decoration-line: underline;
	text-underline-position: under;
	color: #00587c;
`

export const ExternalLink = ({ to, blank, children, ...rest }) => {
	return (
		<Wrapper href={to} {...(blank ? { target: "_blank" } : {})} {...rest}>
			{children}
		</Wrapper>
	)
}
