import React from "react"
import styled from "styled-components"

import { COLOR_WHITE } from "style/colors"

const SVG = styled.svg``
export const NHO = ({
	width = "100px",
	height = "100px",
	color = COLOR_WHITE,
	...rest
}) => {
	return (
		<SVG
			width={width}
			height={height}
			viewBox="0 0 108.61 146.39"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path d="M62.78,114.24v12.53H44.61V114.24H39.09v31.24h5.52V132H62.78v13.47H68.3V114.24Z" />
			<path d="M89.66,113.2c9.86,0,17.27,6.38,17.27,16.51,0,9.86-7.41,16.68-17.27,16.68s-17.15-6.82-17.15-16.68C72.51,119.58,79.93,113.2,89.66,113.2Zm0,28.15c6.47,0,11.12-4.91,11.12-11.64,0-6.52-4.56-11.56-11.12-11.56s-11.07,5-11.07,11.56C78.63,136.44,83.28,141.35,89.7,141.35Z" />
			<path d="M10.42,122.72,28,145.5h5.49V114.24H28v21.88c0,.32-.27.43-.49.19l-17.2-22.07H4.43V145.5H9.92V122.9C9.92,122.58,10.19,122.48,10.42,122.72Z" />
			<path d="M102.79,60.28,77.72,17.35s-1.67-2.72-2.57-4L75,13.2C72.27,9.23,64.72,0,54.2,0h-.06c-8.69,0-16.65,5.87-23,16.87,0,0-24.83,42.43-25,42.75-6.6,11-7.92,21.5-3.59,28.89C7,96.21,16.3,100.15,29.34,99.89l50.06,0c12.7,0,22.4-4.2,26.61-11.46C110.36,81,109.25,71.21,102.79,60.28ZM35.5,19.41C41,9.91,47.27,5.08,54.15,5.06h.05c8.13,0,14.37,7.71,16.68,11l.15.2c4,5.58,6.62,12.82,7.82,20.52,0,0,4.16,24.27,4.78,29.92.53,4.92-1.07,5.91-1.75,6.33S79.55,74.49,72.64,72L67,69.8,61.32,36.26c-1.09-6.39-3-10-6.36-12a10.63,10.63,0,0,0-11.11.53c-3.32,2.08-16.26,13.05-22,18Zm26,48.3L43.23,60.77l15.06-12ZM29.29,94.84C18.3,95,10.35,91.9,6.87,86c-3.3-5.66-2-14.53,3.57-23.73l.63-1.07c1.52-2.65,3.42-5.95,11.79-12.56,0,0,20.53-17.54,23.68-19.5s5.39-.74,5.82-.48c.91.54,2.8,1.67,4,8.49l1,5.92L31.2,63.9C26,68.11,24,71.45,24.05,75.42s2.42,7.18,6.78,9.13c3.63,1.62,23,8.65,27.59,10.3Zm72.34-8.91c-3.29,5.67-11.41,8.92-22.29,8.94,0,0-6.67.31-18.16-4.4,0,0-24.43-8.82-28.29-10.54s-3.77-3.8-3.78-4.61c0-1-.06-3.13,5.27-7.48l4.26-3.4L70.87,76.7a26.24,26.24,0,0,0,8.77,2,9,9,0,0,0,4.89-1.3c3.37-2.08,4.72-5.74,4.13-11.19-.52-4.77-3.37-21.36-4.47-27.72L98.43,62.84C104,72.25,105.06,80,101.63,85.93Z" />
		</SVG>
	)
}
