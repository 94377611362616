import React, { useState } from "react"
import styled, { css } from "styled-components"
import FocusTrap from "focus-trap-react"

import { useMediaPredicate } from "react-media-hook"

const DefaultOuterWrap = styled.div``

const DefaultWrapper = styled.div`
	flex: 1;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	padding: 40px 24px;
	${props =>
		props.open &&
		css`
			height: 100vh;
		`}
`

const DefaultMenu = styled.div`
	display: none;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	@media screen and (min-width: 800px) {
		display: flex;
		/*flex-direction: row;*/
	}
	${props =>
		props.hideClosedDesktop &&
		css`
			@media screen and (min-width: 800px) {
				display: none;
			}
		`}
	${props =>
		props.open &&
		css`
			/* position: fixed; */
			/* bottom: 0; */
			/* left: 0; */
			/* right: 0; */
			flex: 1;
			background-color: ${props => props.backgroundColor || "black"};
			display: flex;
			@media screen and (min-width: 800px) {
				display: flex;
			}
			/*@media screen and (min-width: 800px) {
				flex: unset;
				background-color: transparent;
			}*/
		`}
`

const DefaultBurger = styled.button`
	&:only-child {
		margin-left: auto;
	}
	@media screen and (min-width: 800px) {
		display: none;
	}
	/*z-index: 1000;
	${props =>
		props.open &&
		css`
			position: fixed;
			right: 24px;
			top: 40px;
		`}*/
`

const DefaultNav = styled.nav`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const DefaultMobileMenuElementsWrapper = styled.div``

const DefaultDesktopMenuElementsWrapper = styled.div`
	display: none;
	@media screen and (min-width: 800px) {
		flex: 1;
		margin-right: 48px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: stretch;
		${props =>
			props.open &&
			css`
				display: none;
			`}
	}
`

const Sticky = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
`

/**
 *
 * @param {object} props React props
 * @param {string} props.burgerColor Color of the burger, will be passed down to the burger component
 * @param {string} props.wrapper Wrapper react component
 * @param {string} props.menu Menu react component. Should accept an "open" prop.
 * @param {string} props.mobileMenuElements Elements below the nav elements that only show up on mobile.
 * @param {string} props.burger Burger react component. Should be a button.
 * @param {string} props.children Menu elements
 *
 */
export const Navbar = ({
	burgerColor,
	backgroundColor,
	outerWrapper: OuterWrap,
	wrapper: Wrap,
	logo: Logo,
	menu: MenuWrap,
	nav: Navigation,
	mobileMenuElementsWrapper: MobileMenuElementsWrapper,
	mobileMenuElements,
	desktopMenuElementsWrapper: DesktopMenuElementsWrapper,
	desktopMenuElements,
	burger: Burger,
	hideClosedDesktop,
	children,
}) => {
	const [mobileNavOpen, setMobileNavOpen] = useState(false)

	const isDesktop = useMediaPredicate("(min-width: 800px)")

	const onKeyDown = ({ key }) => {
		if (["Esc", "Escape"].includes(key)) {
			setMobileNavOpen(false)
		}
	}

	const OuterWrapper = OuterWrap || DefaultOuterWrap

	const Wrapper = Wrap || DefaultWrapper

	const Menu = MenuWrap || DefaultMenu

	const Hamburger = Burger || DefaultBurger

	const Nav = Navigation || DefaultNav

	const MobileElements =
		MobileMenuElementsWrapper || DefaultMobileMenuElementsWrapper

	const DesktopElements =
		DesktopMenuElementsWrapper || DefaultDesktopMenuElementsWrapper

	return (
		<FocusTrap active={mobileNavOpen}>
			<OuterWrapper>
				<Wrapper
					open={mobileNavOpen}
					onKeyDown={mobileNavOpen ? onKeyDown : undefined}
				>
					<Sticky>
						{Logo && <Logo />}
						<DesktopElements open={mobileNavOpen}>
							{desktopMenuElements}
						</DesktopElements>
						<Hamburger
							open={mobileNavOpen}
							onClick={() => setMobileNavOpen(!mobileNavOpen)}
							color={burgerColor}
							children={Burger ? undefined : "Burger"}
						/>
					</Sticky>
					<Menu
						className={mobileNavOpen ? "open" : ""}
						open={mobileNavOpen}
						backgroundColor={backgroundColor}
						hideClosedDesktop={hideClosedDesktop}
						//mobileElements={mobileMenuElements}
						//desktopElements={desktopMenuElements}
					>
						<Nav>{children}</Nav>
						{mobileNavOpen && !isDesktop && (
							<MobileElements>
								{mobileMenuElements}
							</MobileElements>
						)}
					</Menu>
				</Wrapper>
			</OuterWrapper>
		</FocusTrap>
	)
}
