import styled from "styled-components"

import { STYLE } from "style"
import { utility } from "style/style-utils"
import { COLOR_MAIN_LIGHT, COLOR_WHITE } from "style/colors"

import { Button } from "components/interactable/Button"

/**
 * Menu Button Component
 * Because menu buttons display very differently on desktop compared
 * to mobile, the best way is to make a special component for it!
 */
export const MenuButton = styled(Button)`
	${utility.button}
	${STYLE.TEXT.MOBILE.MENU}
	${STYLE.TEXT.MOBILE.BUTTON_LARGE}
	position: relative;
	/*@media screen and (min-width: 800px) {
		text-align: center;
		color: ${COLOR_WHITE};
		background: ${COLOR_MAIN_LIGHT};
		border-radius: 6px;
		padding: 12px 8px;
	}*/

	&:disabled {
		/*opacity: 0.4;*/
		color: ${COLOR_WHITE}99;
		background: ${COLOR_MAIN_LIGHT}66;
	}
	&:focus {
		/* Fallback */
		outline: 2px solid currentColor;
		outline-offset: 2px;
		&:not(:focus-visible) {
			/* Hide on normal click */
			outline: none;
		}
	}
	&:focus-visible {
		/* Show on keyboard focus */
		outline: 2px solid currentColor;
		outline-offset: 2px;
	}
	&:hover {
		opacity: 0.8;
	}
`
