import React from "react"
import styled from "styled-components"
import { TextInput } from "./TextInput"

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 32px;
	margin-bottom: 32px;
`

export const ContactFieldsGrid = ({ register, children, ...rest }) => {
	return (
		<Grid {...rest}>
			<TextInput
				name="firstName"
				placeholder="Fornavn"
				label="Fornavn"
				hideLabel
				register={register}
				validation={{ required: true }}
				wrapperProps={{ desktopMaxWidth: "100%" }}
			/>
			<TextInput
				name="lastName"
				placeholder="Etternavn"
				label="Etternavn"
				hideLabel
				register={register}
				validation={{ required: true }}
				wrapperProps={{ desktopMaxWidth: "100%" }}
			/>
			<TextInput
				name="email"
				placeholder="Epost"
				label="Epost"
				hideLabel
				register={register}
				validation={{ required: true, pattern: /^\S+@\S+$/i }}
				wrapperProps={{ desktopMaxWidth: "100%" }}
			/>
			<TextInput
				name="phone"
				placeholder="Telefonnummer"
				label="Telefonnummer"
				hideLabel
				register={register}
				validation={{ required: true }}
				wrapperProps={{ desktopMaxWidth: "100%" }}
			/>
			{children}
		</Grid>
	)
}
