import React from "react"
import styled from "styled-components"
import BaseImage from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

const sanityConfig = { projectId: "x5o90l5y", dataset: "production" }

const Wrapper = styled.div`
	align-self: ${props => props.align};
`

const Image = styled(BaseImage)``

/**
 * EmbeddableImage component. Shows an image that can be aligned
 * @param {*} param0
 * @returns
 */

export const EmbeddableImage = ({ alt, image, align, link, ...rest }) => {
	console.log({ alt, image, align, link })

	const fluidProps = image?.asset?.fluid
		? image?.asset?.fluid
		: getFluidGatsbyImage(
				image?.asset?._id,
				{ maxWidth: 1200 },
				sanityConfig
		  )

	const alignMap = {
		left: "flex-start",
		center: "center",
		right: "flex-end",
	}

	console.log({ fluidProps })

	if (!fluidProps) return null

	console.log({ fluidProps })

	return (
		<Wrapper align={alignMap[align] || "center"} {...rest}>
			<Image fluid={fluidProps} alt={alt} />
		</Wrapper>
	)
}
