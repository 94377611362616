import { Link } from "gatsby"

export const getExternalLinkProps = externalLink => {
	const { href, blank } = externalLink || {}
	//console.log({ href, blank })
	const url = href || ""
	const target = blank ? { target: "_blank" } : {}
	return {
		as: Link,
		to: url,
		...target,
	} //target is only added if blank is true
}

export const getPropsFromReference = reference => {
	const {
		_type,
		parent,
		slug: { current },
	} = reference || {}
	console.log({ _type, parent, current })
	switch (_type) {
		case "child_page":
			return {
				as: Link,
				to: `/${parent.slug.current}/${current}`,
			}
		case "page":
		case "post":
		case "packages":
		case "team":
		case "order":
		default:
			return {
				as: Link,
				to: `/${current}`,
			}
	}
}

export const getInternalLinkProps = ({ reference }) => {
	return getPropsFromReference(reference)
}

export const getLinkProps = (internal, external) => {
	/*console.log("GetLinkProps", {
		internal,
		external,
		notEither: !(internal || external),
	})*/
	if (!(internal?.reference || external)) {
		return {
			as: "span",
		}
	}
	/*console.log({
		inter: Boolean(internal),
		type: internal?._type,
		ext: Boolean(external),
	})*/
	return internal
		? getInternalLinkProps(internal)
		: getExternalLinkProps(external)
}

export const getLinkPropsFromRawData = rawData => {
	//console.log("GetLinkPropsFromRawData", { rawData })
	const { linkInternal, linkExternal } = rawData || {}
	return getLinkProps(linkInternal, linkExternal)
}

export const linkRawDataHasFields = rawData => {
	const { linkInternal, linkExternal } = rawData || {}
	return Boolean(linkInternal || linkExternal)
}

export const getRawDataLinkTo = rawData => {
	return getLinkPropsFromRawData(rawData)?.to
}

export const getLinkPropsFromReference = reference => {
	//console.log("GetLinkPropsFromReference", { reference })
	if (!reference) return { as: "span" }
	return getPropsFromReference(reference)
}
