import React from "react"
import styled from "styled-components"

import { COLOR_WHITE } from "style/colors"

const SVG = styled.svg``
export const Facebook = ({ color = COLOR_WHITE, ...rest }) => {
	return (
		<SVG
			width="40"
			height="38"
			viewBox="0 0 40 38"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path d="M22.7247 32.6615V20.1164H27.2637L27.9446 15.2259H22.7247V12.104C22.7247 10.6885 23.1468 9.72389 25.3381 9.72389L28.1284 9.72283V5.34858C27.6458 5.29043 25.9895 5.1571 24.0616 5.1571C20.036 5.1571 17.28 7.4358 17.28 11.6196V15.2259H12.7273V20.1164H17.28V32.6615H22.7247Z" />
		</SVG>
	)
}
