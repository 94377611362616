import { useState, useEffect } from 'react'

/**
 * https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
 */
export const useIsClient = () => {
    const [isClient, setClient] = useState(false)
    const key = isClient ? 'client' : 'server'

    useEffect(() => {
        setClient(true)
    }, [])
    

    return { isClient, key } //object, so you can extract whichever attributes you want
}
