import styled, { css } from "styled-components"

import { STYLE } from "style"
import { utility } from "style/style-utils"
import { COLOR_WHITE } from "style/colors"
import { COLOR_MAIN_LIGHT } from "style/colors"

export const Button = styled.button`
	${utility.button}
	${props =>
		props.small
			? STYLE.TEXT.MOBILE.BUTTON_SMALL
			: STYLE.TEXT.MOBILE.BUTTON_LARGE}
    text-align: center;
	color: ${COLOR_WHITE};
	background: ${COLOR_MAIN_LIGHT};
	/*border-radius: 6px;*/
	/*width: 100%;*/
	border-radius: 6px;
	padding: ${props => (props.small ? "8px" : "12px 8px")};
	&:disabled {
		/*opacity: 0.4;*/
		color: ${COLOR_WHITE}99;
		background: ${COLOR_MAIN_LIGHT}66;
	}
	&:focus {
		/* Fallback */
		outline: 2px solid currentColor;
		outline-offset: 2px;
		&:not(:focus-visible) {
			/* Hide on normal click */
			outline: none;
		}
	}
	&:focus-visible {
		/* Show on keyboard focus */
		outline: 2px solid currentColor;
		outline-offset: 2px;
	}
	&:hover {
		opacity: 0.8;
	}
	${props =>
		props.width &&
		css`
			width: ${props.width};
		`}
	${props =>
		props.dWidth &&
		css`
			@media screen and (min-width: 800px) {
				width: ${props.width};
			}
		`}
	${props =>
		props.maxWidth &&
		css`
			max-width: ${props.maxWidth};
		`}
	${props =>
		props.dMaxWidth &&
		css`
			@media screen and (min-width: 800px) {
				max-width: ${props.dMaxWidth};
			}
		`}
	${props =>
		props.nowrap &&
		css`
			white-space: nowrap;
		`}
`
