import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

//import { getLinkProps } from "linkFunctions"

import { Text } from "components/text/Text"
import { TitleSmall } from "components/text/TitleSmall"
import { Bold } from "components/text/Bold"

import { ContentPortableText } from "components/blockContent/ContentPortableText"
import { getLinkPropsFromRawData } from "../../../linkFunctions"

const sanityConfig = { projectId: "x5o90l5y", dataset: "production" }

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0 0 0 10px; 
	color: inherit;
	text-decoration: none;
`

const Image = styled(Img)`
	width: 100%;

	height: 50vh;
	@media screen and (min-width: 800px) {
		height: 390px;
	}
`

const Placeholder = styled.div``

const BlockRenderer = props => {
	const { style = "normal" } = props.node

	if (style === "normal") {
		return <Text>{props.children}</Text>
	}

	if (style === "h2") {
		return <TitleSmall as="h3">{props.children}</TitleSmall>
	}

	if (style === "h3") {
		return (
			<Text as="h4">
				<Bold>{props.children}</Bold>
			</Text>
		)
	}

	if (style === "blockquote") {
		return <Text>- {props.children}</Text>
	}

	// Fall back to default handling
	return BlockContent.defaultSerializers.types.block(props)
}

const serializers = {
	types: {
		block: BlockRenderer,
	},
}

const Content = ({ title, text, image, ...rest }) => {
	console.log("infoCard content", { text, image })

	const fluidProps = image?.image?.asset?.fluid
		? image?.image?.asset?.fluid
		: getFluidGatsbyImage(
				image?.image?.asset?._id,
				{ maxWidth: 1200 },
				sanityConfig
		  )
	return (
		<Wrapper className="info-card" {...rest}>
			{image?.image ? (
				<Image fluid={fluidProps} alt={image?.alt} />
			) : (
				<Placeholder className="placeholder" />
			)}
			<TitleSmall>{title}</TitleSmall>
			<ContentPortableText blocks={text} serializers={serializers} />
		</Wrapper>
	)
}

export const InfoCard = props => {
	const { link, ...rest } = props || {}
	console.log("InfoCard", { link, props })

	if (!link) return <Content {...rest} />

	const { label, data, _rawData } = link || {}

	const actualData = data || _rawData
	return (
		<Content
			aria-label={label}
			{...getLinkPropsFromRawData(actualData)}
			{...rest}
		/>
	)
}
