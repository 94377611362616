import React from "react"
import styled, { css } from "styled-components"
//import { utility } from "../../style/style-utils"

const SVG = styled.svg``

const Circle = styled.path``

const Bars = styled.g``

const Text = styled.g``

const SmallText = styled.g`
	${props =>
		props.hide &&
		css`
			display: none;
		`}
`

export const CleanGym = ({
	width = "100px",
	height = "100px",
	color = "#0bbbef",
	textColor = "white",
	barColor = "black",
	hideSmallText,
	...rest
}) => {
	return (
		<SVG
			width={width}
			height={height}
			viewBox="0 0 112.99 112.99"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<Circle
				d="M113.19,56.69A56.5,56.5,0,1,1,56.69.2a56.51,56.51,0,0,1,56.5,56.49"
				transform="translate(-0.2 -0.2)"
			/>
			<Bars fill={barColor}>
				<rect x="23.73" y="67.42" width="65.53" height="5.96" />
				<rect x="23.73" y="75.36" width="65.53" height="5.96" />
				<rect x="23.73" y="83.3" width="65.53" height="5.96" />
				<rect x="23.73" y="23.73" width="65.53" height="5.96" />
				<rect x="23.73" y="31.67" width="65.53" height="5.96" />
				<rect x="23.73" y="39.62" width="65.53" height="5.96" />
			</Bars>
			<Text fill={textColor}>
				<path
					d="M10.51,52.21H14.8a4,4,0,0,1,2.95,1,2.84,2.84,0,0,1,.81,2.11v0a2.88,2.88,0,0,1-1.87,2.8l2.16,3.16H16l-1.83-2.75H13v2.75H10.51Zm4.19,4.35c.84,0,1.34-.41,1.34-1.07v0c0-.72-.53-1.09-1.36-1.09H13v2.19Z"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="19.63 52.01 26.93 52.01 26.93 54.14 22.12 54.14 22.12 55.52 26.47 55.52 26.47 57.51 22.12 57.51 22.12 58.94 26.99 58.94 26.99 61.08 19.63 61.08 19.63 52.01" />
				<polygon points="28.29 52.01 30.63 52.01 34.36 56.8 34.36 52.01 36.85 52.01 36.85 61.08 34.66 61.08 30.77 56.11 30.77 61.08 28.29 61.08 28.29 52.01" />
				<polygon points="40.68 54.21 37.97 54.21 37.97 52.01 45.91 52.01 45.91 54.21 43.19 54.21 43.19 61.08 40.68 61.08 40.68 54.21" />
				<path
					d="M50.69,60l.63-.75a4.34,4.34,0,0,0,3.11,1.28c1.22,0,2-.65,2-1.54v0c0-.84-.45-1.32-2.36-1.72-2.08-.46-3-1.13-3-2.62v0c0-1.43,1.26-2.48,3-2.48a4.74,4.74,0,0,1,3.19,1.12l-.6.79A4,4,0,0,0,54,53c-1.18,0-1.93.65-1.93,1.46v0c0,.86.47,1.34,2.46,1.76s3,1.18,3,2.57v0c0,1.56-1.3,2.57-3.1,2.57A5.28,5.28,0,0,1,50.69,60"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="59.38 52.01 65.93 52.01 65.93 52.94 60.4 52.94 60.4 56.04 65.35 56.04 65.35 56.97 60.4 56.97 60.4 60.15 66 60.15 66 61.08 59.38 61.08 59.38 52.01" />
				<polygon points="68.06 52.01 69.02 52.01 74.73 59.28 74.73 52.01 75.73 52.01 75.73 61.08 74.91 61.08 69.06 53.64 69.06 61.08 68.06 61.08 68.06 52.01" />
				<polygon points="80.69 52.96 77.65 52.96 77.65 52.01 84.78 52.01 84.78 52.96 81.73 52.96 81.73 61.08 80.69 61.08 80.69 52.96" />
				<polygon points="86.7 52.01 93.25 52.01 93.25 52.94 87.72 52.94 87.72 56.04 92.67 56.04 92.67 56.97 87.72 56.97 87.72 60.15 93.32 60.15 93.32 61.08 86.7 61.08 86.7 52.01" />
				<path
					d="M95.58,52.21h3.9a3.59,3.59,0,0,1,2.58.91,2.5,2.5,0,0,1,.7,1.79v0a2.66,2.66,0,0,1-2.47,2.64l2.79,3.71h-1.26l-2.63-3.52H96.6v3.52h-1Zm3.81,4.63c1.36,0,2.33-.7,2.33-1.87v0c0-1.11-.86-1.78-2.32-1.78H96.6v3.68Z"
					transform="translate(-0.2 -0.2)"
				/>
			</Text>
			<SmallText fill={textColor} hide={hideSmallText}>
				<rect x="37.79" y="93.83" width="0.5" height="2.84" />
				<path
					d="M40.28,96.46l.3-.36a1.34,1.34,0,0,0,.9.37c.3,0,.5-.15.5-.36h0c0-.2-.11-.31-.64-.43s-1-.33-1-.85h0a.87.87,0,0,1,1-.82,1.55,1.55,0,0,1,1,.35l-.26.38a1.33,1.33,0,0,0-.78-.29c-.29,0-.46.15-.46.34h0c0,.22.13.32.68.45s.91.36.91.83h0c0,.53-.42.85-1,.85a1.76,1.76,0,0,1-1.18-.45"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M44,94h.46l1.25,2.86H45.2l-.29-.69H43.57l-.29.69h-.52Zm.71,1.73-.49-1.14-.49,1.14Z"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="46.02 93.83 46.55 93.83 47.42 95.17 48.28 93.83 48.81 93.83 48.81 96.67 48.31 96.67 48.31 94.63 47.42 95.97 47.4 95.97 46.51 94.64 46.51 96.67 46.02 96.67 46.02 93.83" />
				<path
					d="M50.75,94h.46l1.26,2.86h-.53l-.29-.69H50.3l-.29.69H49.5Zm.72,1.73L51,94.6l-.49,1.14Z"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M53,94h1.27a1.13,1.13,0,0,1,.82.28.88.88,0,0,1,.24.61h0a.85.85,0,0,1-.68.86l.77,1.08h-.59l-.7-1h-.63v1H53Zm1.24,1.41c.35,0,.58-.19.58-.48h0c0-.3-.22-.47-.59-.47h-.73v1Z"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M55.89,94h1.28a1,1,0,0,1,.74.25.61.61,0,0,1,.19.47h0a.67.67,0,0,1-.4.63.71.71,0,0,1,.56.69h0c0,.51-.42.78-1,.78H55.89Zm1.71.8c0-.22-.18-.36-.5-.36h-.72v.76h.69c.32,0,.53-.13.53-.39Zm-.42.82h-.8v.78h.83c.34,0,.55-.14.55-.39h0c0-.24-.19-.38-.58-.38"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="58.63 93.83 60.73 93.83 60.73 94.28 59.13 94.28 59.13 95.01 60.55 95.01 60.55 95.46 59.13 95.46 59.13 96.22 60.76 96.22 60.76 96.67 58.63 96.67 58.63 93.83" />
				<rect x="61.38" y="93.83" width="0.5" height="2.84" />
				<path
					d="M62.84,94H63.9a1.42,1.42,0,0,1,1.51,1.41h0a1.42,1.42,0,0,1-1.51,1.42H62.84Zm.5.45v1.93h.56a.92.92,0,0,0,1-1h0a.93.93,0,0,0-1-1Z"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="67.03 93.83 67.56 93.83 68.43 95.17 69.3 93.83 69.83 93.83 69.83 96.67 69.33 96.67 69.33 94.63 68.43 95.97 68.41 95.97 67.52 94.64 67.52 96.67 67.03 96.67 67.03 93.83" />
				<polygon points="70.56 93.83 72.67 93.83 72.67 94.28 71.06 94.28 71.06 95.01 72.48 95.01 72.48 95.46 71.06 95.46 71.06 96.22 72.69 96.22 72.69 96.67 70.56 96.67 70.56 93.83" />
				<path
					d="M73.48,94h1.06a1.42,1.42,0,0,1,1.51,1.41h0a1.42,1.42,0,0,1-1.51,1.42H73.48Zm.5.45v1.93h.56a.92.92,0,0,0,1-1h0a.93.93,0,0,0-1-1Z"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M36.27,98.88h.46L38,101.75h-.52l-.29-.69H35.82l-.29.69H35Zm.71,1.74-.49-1.14L36,100.62Z"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="38.27 98.7 38.74 98.7 40.26 100.67 40.26 98.7 40.75 98.7 40.75 101.55 40.33 101.55 38.77 99.52 38.77 101.55 38.27 101.55 38.27 98.7" />
				<polygon points="42.18 99.17 41.28 99.17 41.28 98.7 43.59 98.7 43.59 99.17 42.69 99.17 42.69 101.55 42.18 101.55 42.18 99.17" />
				<rect x="44.14" y="98.7" width="0.5" height="2.84" />
				<path
					d="M45.6,98.9h1.06a1.42,1.42,0,0,1,1.51,1.42h0a1.43,1.43,0,0,1-1.51,1.43H45.6Zm.5.46v1.93h.56a.93.93,0,0,0,1-1h0a.93.93,0,0,0-1-1Z"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M48.65,100.33h0a1.49,1.49,0,0,1,3,0h0a1.49,1.49,0,0,1-3,0m2.45,0h0a1,1,0,0,0-1-1,1,1,0,0,0-1,1h0a1,1,0,0,0,1,1,1,1,0,0,0,1-1"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M52.23,98.9h1.12a1,1,0,0,1,1.09.95h0c0,.64-.52,1-1.14,1h-.57v.92h-.5Zm1.08,1.48c.38,0,.62-.21.62-.51h0c0-.33-.24-.5-.62-.5h-.58v1Z"
					transform="translate(-0.2 -0.2)"
				/>
				<rect x="54.78" y="98.7" width="0.5" height="2.84" />
				<polygon points="56.04 98.7 56.5 98.7 58.02 100.67 58.02 98.7 58.52 98.7 58.52 101.55 58.1 101.55 56.53 99.52 56.53 101.55 56.04 101.55 56.04 98.7" />
				<path
					d="M59.32,100.33h0a1.45,1.45,0,0,1,1.47-1.47,1.53,1.53,0,0,1,1.09.39l-.32.38a1.09,1.09,0,0,0-.79-.31,1,1,0,0,0-.93,1h0a1,1,0,0,0,1,1,1.18,1.18,0,0,0,.69-.22v-.53h-.73v-.43H62v1.19a1.81,1.81,0,0,1-1.19.44,1.4,1.4,0,0,1-1.47-1.46"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="63.65 98.7 64.11 98.7 65.64 100.67 65.64 98.7 66.13 98.7 66.13 101.55 65.71 101.55 64.14 99.52 64.14 101.55 63.65 101.55 63.65 98.7" />
				<path
					d="M66.93,100.33h0a1.49,1.49,0,0,1,3,0h0a1.49,1.49,0,0,1-3,0m2.45,0h0a1,1,0,0,0-1-1,1,1,0,0,0-1,1h0a1,1,0,1,0,1.92,0"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M70.51,98.9h1.27a1.13,1.13,0,0,1,.82.29.84.84,0,0,1,.24.61h0a.85.85,0,0,1-.68.86l.77,1.08h-.59l-.7-1H71v1h-.5Zm1.23,1.41c.36,0,.59-.19.59-.47h0c0-.31-.22-.47-.59-.47H71v1Z"
					transform="translate(-0.2 -0.2)"
				/>
				<path
					d="M73.33,100.33h0a1.44,1.44,0,0,1,1.46-1.47,1.51,1.51,0,0,1,1.09.39l-.31.38a1.12,1.12,0,0,0-.8-.31,1,1,0,0,0-.92,1h0a1,1,0,0,0,1,1,1.15,1.15,0,0,0,.68-.22v-.53h-.73v-.43H76v1.19a1.83,1.83,0,0,1-1.19.44,1.4,1.4,0,0,1-1.47-1.46"
					transform="translate(-0.2 -0.2)"
				/>
				<polygon points="76.44 98.7 78.55 98.7 78.55 99.15 76.94 99.15 76.94 99.89 78.36 99.89 78.36 100.34 76.94 100.34 76.94 101.1 78.57 101.1 78.57 101.55 76.44 101.55 76.44 98.7" />
			</SmallText>
		</SVG>
	)
}
