import React from "react"
import styled, { css } from "styled-components"
import { STYLE } from "style"
import { graphql, useStaticQuery } from "gatsby"

const Wrapper = styled.p`
	${STYLE.TEXT.MOBILE.PHONE}
	${props =>
		props.margin &&
		css`
			margin: ${props.margin};
		`}
	${props =>
		props.mb &&
		css`
			margin-bottom: ${props.mb};
		`}
`

export const Phone = ({ margin, mb, ...rest }) => {
	const { phone } = useStaticQuery(graphql`
		query GetPhoneNumber {
			sanitySiteSettings {
				phone
			}
		}
	`)?.sanitySiteSettings

	return (
		<Wrapper margin={margin} mb={mb} {...rest}>
			{phone}
		</Wrapper>
	)
}
