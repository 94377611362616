import styled from "styled-components"

import { STYLE } from "style"

import { textPropHandler } from "./textPropHandler"

export const SmallText = styled.p`
	${STYLE.TEXT.MOBILE.TEXT_SMALL}
	color: ${props => props.color || "inherit"};
	@media screen and (min-width: 800px) {
		${STYLE.TEXT.DESKTOP.TEXT_SMALL}
	}
	${textPropHandler}
	margin: 0;
	margin-bottom: ${props => props.mb || "18px"};
`
