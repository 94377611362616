import React, { useState } from "react"
import { useStaticQuery } from "gatsby"

import { Banner } from "components/interactable/Banner"
import {
	setLocalStorageWithExpiry,
	getLocalStorageWithExpiry,
} from "utilityFunctions"
import { useIsClient } from "../../hooks/useIsClient"

export const CoronaBanner = () => {
	const data = useStaticQuery(query)

	const { isClient } = useIsClient()

	const [hide, setHide] = useState(
		data?.visible === true // ensure it's not merely unset
			? isClient
				? getLocalStorageWithExpiry("infoBanner")
				: false
			: true //hide if not visible
	)

	const closeHandler = () => {
		console.log("CoronaBanner: closeHandler")
		const oneYearMS = 1000 * 60 * 60 * 24 * 365
		setLocalStorageWithExpiry("infoBanner", true, oneYearMS)
		setHide(true)
	}

	if (hide) return null

	return (
		<Banner
			buttonIsCross
			bgColor="yellow"
			color="black"
			buttonText="Lukk infoboks"
			text={data?.sanitySiteSettings?.banner?._rawText}
			onClose={closeHandler}
		/>
	)
}

const query = graphql`
	query GetBanner {
		sanitySiteSettings {
			banner {
				visible
				_rawText(resolveReferences: { maxDepth: 10 })
			}
		}
	}
`
