import React from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import axios from "axios"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { STYLE } from "style"

import { ContactFieldsGrid } from "components/interactable/ContactFieldsGrid"
import { Button } from "components/interactable/Button"

import { Text } from "components/text/Text"

const Form = styled.form``

const Label = styled(Text).attrs({ as: "label" })`
	margin-left: 16px;
`

const Textarea = styled.textarea`
	${STYLE.TEXT.MOBILE.TEXT}
	width: 100%;
	min-height: 200px;
	margin-bottom: 32px;

	padding: 8px 16px;
	border: 1px solid #dbdee7;
	border-radius: 6px;
	&:focus-within {
		background-color: #be530911;
	}
	@media screen and (min-width: 800px) {
		${STYLE.TEXT.DESKTOP.TEXT}
	}
`

export const ContactForm = ({ button_text = "Send melding" }) => {
	const { register, handleSubmit, watch } = useForm()

	const message = watch("message")
	console.log({ message })

	const onSubmit = data => {
		console.log("onSubmit", { data })
		const { message, firstName, lastName, email, phone } = data || {}
		if (!message) {
			return toast.error("Du må ha en melding")
		}
		if (!firstName) {
			return toast.error("Du må oppgi fornavn")
		}
		if (!lastName) {
			return toast.error("Du må oppgi etternavn")
		}
		if (!email || !phone) {
			return toast.error("Du må oppgi epost eller telefon")
		}
		data.type = 'contact';
		toast("Sender epost...")
		axios
			.post(
				"https://utils.wayscloud.no/skullerudcontact.php",
				data
			)
			.then(response => {
				console.log("Success", { response })
				toast.success("Meldingen er sendt!")
			})
			.catch(response => {
				console.log("Failure", { response })
				toast.error("Auda, noe gikk galt. Prøv igjen!")
			})
	}

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<ContactFieldsGrid register={register} />
			<Label>
				Skriv din melding til oss
				<Textarea
					name="message"
					ref={register}
					placeholder="Skriv her"
				/>
			</Label>
			<Button center disabled={!message || message === ""}>
				{button_text}
			</Button>
			<ToastContainer />
		</Form>
	)
}
