import React from "react"
import styled from "styled-components"

import { STYLE } from "style"
import { MenuButton } from "./MenuButton"
import { graphql, useStaticQuery } from "gatsby"
import { getLinkPropsFromRawData } from "../../linkFunctions"

const Item = styled.li`
	${STYLE.TEXT.MOBILE.MENU}
	margin-bottom: 18px;
	&:last-child {
		margin-bottom: 0;
	}
	@media screen and (min-width: 800px) {
	}
`

const Wrapper = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	padding: 0;
	@media screen and (min-width: 800px) {
		/*flex-direction: row;
		> ${Item} {
			margin-bottom: 0;
			margin-right: 24px;

			&:last-child {
				margin-right: 0;
			}
		}*/
	}
`

export const MenuItems = () => {
	const {
		settings: { menu_elements },
	} = useStaticQuery(query)

	console.log({ menu_elements })
	return (
		<Wrapper>
			{menu_elements.map(item => {
				//console.log({ item })
				const {
					_key,
					label,
					/*data: {
						options: { linkExternal, linkInternal },
					},*/
					_rawData,
				} = item || {}
				//console.log({ label, linkInternal, linkExternal })

				return (
					<Item key={_key}>
						<MenuButton {...getLinkPropsFromRawData(_rawData)}>
							{label}
						</MenuButton>
					</Item>
				)
			})}
		</Wrapper>
	)
}

const query = graphql`
	{
		settings: sanitySiteSettings {
			menu_elements {
				_key
				label
				_rawData(resolveReferences: { maxDepth: 10 })
			}
		}
	}
`
