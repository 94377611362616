import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"
import getYouTubeId from "get-youtube-id"
import YouTube from "react-youtube"
//import { Link } from "gatsby"

import { getLinkPropsFromRawData } from "linkFunctions"

import { Box } from "components/elements/Box/Box"
import { ArticleCard } from "components/elements/ArticleCard/ArticleCard"
import { EmbeddableImage } from "components/elements/EmbeddableImage"

import { Gallery } from "components/interactable/Gallery"
import { Button as BaseButton } from "components/interactable/Button"

import { Text } from "components/text/Text"
import { TitleSmall } from "components/text/TitleSmall"
import { Bold } from "components/text/Bold"
import { GoogleMaps } from "../elements/GoogleMaps/GoogleMaps"
import { ExternalLink } from "../interactable/ExternalLink"
import { InternalLink } from "../interactable/InternalLink"
import { ContactForm } from "../interactable/ContactForm"

const Blocks = styled(BlockContent)`
	display: contents;
	.youtube-container {
		width: 100%;
		padding-bottom: 56%;
		position: relative;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}
	}
	> *:not(:last-child) {
		margin-bottom: 24px;
	}
`

const Button = styled(BaseButton)`
	width: auto;
	margin: auto;
	min-width: 320px;
	max-width: 100%;
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`

const BlockRenderer = props => {
	const { style = "normal" } = props.node

	if (style === "normal") {
		return <Text>{props.children}</Text>
	}

	if (style === "h2") {
		return <TitleSmall as="h2">{props.children}</TitleSmall>
	}

	if (style === "h3") {
		return (
			<Text as="h3">
				<Bold>{props.children}</Bold>
			</Text>
		)
	}

	if (style === "blockquote") {
		return <Text>- {props.children}</Text>
	}

	// Fall back to default handling
	return BlockContent.defaultSerializers.types.block(props)
}

const contentTypes = {
	splitRow: props => {
		console.log("Serializer: splitRow: ", { props })
		const { image, image_on_right, title, text, links } = props?.node || {}
		return (
			<ArticleCard
				title={title}
				imageOnRight={image_on_right}
				content={text}
				image={image}
				links={links}
			/>
		)
	},
	popBox: props => {
		console.log("Serializer: popBox: ", { props })
		const { title, text, link } = props?.node || {}
		return (
			<Box
				title={title}
				text={text}
				buttonText={link?.label}
				buttonTo={link?.data}
			/>
		)
	},
	cardGallery: props => {
		console.log("Serializer: cardGallery: ", { props })
		const { cards } = props?.node || {}
		const items = cards?.map(item => item)

		return <Gallery items={items} />
	},
	embeddableButton: props => {
		console.log("Serializer: embeddableButton: ", { props })
		const { link } = props?.node || {}
		const { label, data } = link || {}
		return <Button {...getLinkPropsFromRawData(data)}>{label}</Button>
	},
	embeddableImage: props => {
		console.log("Serializer: embeddableImage: ", { props })
		const { image, alt, link, align } = props?.node || {}
		return (
			<EmbeddableImage
				link={link}
				align={align}
				image={image}
				alt={alt}
			/>
		)
	},
	youtube: props => {
		console.log("Serializer: youtube: ", { props })
		const { url } = props?.node || {}
		const id = getYouTubeId(url)
		console.log({ url, id })
		return <YouTube videoId={id} containerClassName="youtube-container" />
	},
	gmaps: props => {
		console.log("Serializer: gmaps: ", { props })
		const { embed } = props?.node || {}
		const result = embed?.match(/src="(.+?)"/)
		const id = result?.[1]

		return <GoogleMaps id={id} />
	},
	contactForm: props => {
		console.log("Serializer: contactForm: ", { props })
		const { visible } = props?.node || {}
		if (!visible) return null
		return <ContactForm />
	},
	block: BlockRenderer,
}

const contentMarks = {
	internalLink: props => {
		console.log("Serializer: internalLink: ", { props })
		const { reference } = props?.mark || {}
		const slug = reference?.slug?.current
		return <InternalLink to={`/${slug}`}>{props?.children}</InternalLink>
	},
	externalLink: props => {
		console.log("Serializer: externalLink: ", { props })
		const { href, blank } = props?.mark || {}
		return (
			<ExternalLink to={href} blank={blank}>
				{props?.children}
			</ExternalLink>
		)
	},
	strong: props => {
		console.log("Decorator: strong!", { props })
		return <Bold>{props?.children}</Bold>
	},
}

export const ContentPortableText = ({ serializers = {}, blocks, ...rest }) => {
	return (
		<Blocks
			serializers={{
				types: {
					...contentTypes,
					...(serializers?.types || {}),
				},
				marks: {
					...contentMarks,
					...(serializers?.marks || {}),
				},
				...serializers,
			}}
			blocks={blocks}
			{...rest}
		/>
	)
}
