import React from "react"
import { Link as BaseLink } from "gatsby"
import styled from "styled-components"

import { ArrowIcon } from "components/svg/ArrowIcon"
import { HiddenText } from "components/text/HiddenText"

const Link = styled(BaseLink)`
	display: block;
`

const Arrow = styled(ArrowIcon)`
	width: 36.64px;
	height: 36px;
	@media screen and (min-width: 800px) {
		width: 57px;
		height: 56px;
	}
`

export const NextButton = ({ alt, to, ...rest }) => {
	return (
		<Link to={to} {...rest}>
			<Arrow />
			<HiddenText>{alt}</HiddenText>
		</Link>
	)
}
