import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
	width: 100%;
	padding-bottom: 56%;
	position: relative;
`

const Iframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
`

export const GoogleMaps = ({ id }) => {
	return (
		<Wrapper>
			<Iframe
				src={id}
				width="600"
				height="450"
				frameBorder="0"
				style={{ border: 0 }}
				allowFullScreen=""
				tabIndex="0"
				aria-hidden="false"
			/>
		</Wrapper>
	)
}
