import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

import { Header } from "./Header"
import { Footer } from "./Footer"
import { CoronaBanner } from "./complete/CoronaBanner"

/** Import fonts */
import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/700.css"
import "@fontsource/inter/900.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"
import { CookieBanner } from "./interactable/CookieBanner"
import { STYLE } from "../style"
import { isBrowser } from "../utilityFunctions"

const GlobalStyle = createGlobalStyle`
  	${normalize}
  
  	html {
		box-sizing: border-box;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	li {
		${STYLE.TEXT.MOBILE.TEXT}
		color: "inherit";
		@media screen and (min-width: 800px) {
			${STYLE.TEXT.DESKTOP.TEXT}
		}
	}
`

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

/**
 * This is the base layout for all pages, sharing behaviour across them all.
 * This is, as such, the optimal place to dump global styles as well.
 */
export const Layout = ({ meta, children, location }) => {

	const data = useStaticQuery(query)

	const { site, sanitySiteSettings, allSanityPage } = data || {}

	const { lang, author, description, title, siteUrl } =
		site?.siteMetadata || {}

	const slugs = allSanityPage.nodes.reduce((nodes, node) => {
		nodes[node.slug.current] = node.slug.current
		return nodes
	}, {})

	const slug = isBrowser() && window?.location?.pathname.slice(1)

	return (
		<Wrapper>
			<GlobalStyle />
			<Helmet
				htmlAttributes={{
					lang: lang || "nb-NO",
				}}
			>
				<meta charSet="utf-8" />
				<meta name="author" content={author} />
				<meta httpEquiv="Cache-control" content="public" />
				<meta
					name="description"
					content={
						meta?.description ||
						sanitySiteSettings?.meta?.description ||
						description
					}
				/>
				<title>
					{meta?.title || sanitySiteSettings?.meta?.title || title}
				</title>
				<link rel="canonical" href={`${siteUrl}/${slugs[slug] || slug}`} />
				<script>
					{`// Snap Pixel
					(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function() {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};a.queue=[];var s='script';r=t.createElement(s);r.async=!0;r.src=n;var u=t.getElementsByTagName(s)[0];u.parentNode.insertBefore(r,u);})(window,document,'https://sc-static.net/scevent.min.js');snaptr('init', '7a2fe124-20b1-499f-9c70-5c965f6d31c8');snaptr('track', 'PAGE_VIEW');`}
				</script>
			</Helmet>
			<CoronaBanner />
			<CookieBanner />
			<Header />
			<Content>{children}</Content>
			<Footer />
		</Wrapper>
	)
}

const query = graphql`
	query GetSiteMetadata {
		site {
			siteMetadata {
				siteUrl
				title
				lang
				description
				author
			}
		}
		sanitySiteSettings {
			meta {
				title
				description
			}
		}
		allSanityPage {
			nodes {
				slug {
					current
				}
			}
		}
	}
`
