import styled from 'styled-components'
import { Text } from './Text'

export const ErrorText = styled(Text)`
    margin-bottom: 16px;
    color: red;
    @media screen and (min-width: 800px) {
        color: red;
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
    }
`
