import React from "react"
import styled from "styled-components"

import { graphql, Link as BaseLink, useStaticQuery } from "gatsby"

import { Facebook } from "components/svg/Facebook"
import { Instagram } from "components/svg/Instagram"
import { NHO } from "components/svg/NHO"
import { CleanGym as BaseCleanGym } from "components/svg/CleanGym"
import { getLinkPropsFromRawData } from "../../linkFunctions"
import { HiddenText } from "../text/HiddenText"
import GatsbyImage from "gatsby-image"

const Row = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 0;
	margin-top: 100px;
	margin-bottom: 40px;
	> *:not(:last-child) {
		margin-bottom: 16px;
	}
	@media screen and (min-width: 800px) {
		flex-direction: row;
		margin-left: 50px;
		> *:not(:last-child) {
			margin-bottom: 0;
			margin-right: 90px;
		}
	}
`

const Small = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 16px;
	@media screen and (min-width: 800px) {
		margin-bottom: 0;
		> *:not(:last-child) {
			margin-right: 10px;
		}
	}
`

const Image = styled(GatsbyImage)``

const Link = styled(BaseLink)``

const CleanGym = styled(BaseCleanGym)`
	width: 150px;
	height: 150px;
	@media screen and (min-width: 800px) {
		width: 100px;
		height: 100px;
	}
	/*margin-left: 50px;*/
`

export const Social = ({ ...rest }) => {
	const query = useStaticQuery(graphql`
		query {
			imageSharp(
				fixed: { originalName: { eq: "TrygtTreningssenter.png" } }
			) {
				safeImageFixed: fixed(height: 200) {
					...GatsbyImageSharpFixed
				}
			}
			sanitySiteSettings {
				social {
					safe {
						_rawData(resolveReferences: { maxDepth: 10 })
						label
					}
					nho {
						_rawData(resolveReferences: { maxDepth: 10 })
						label
					}
					clean {
						_rawData(resolveReferences: { maxDepth: 10 })
						label
					}
					facebook {
						_rawData(resolveReferences: { maxDepth: 10 })
						label
					}
					instagram {
						_rawData(resolveReferences: { maxDepth: 10 })
						label
					}
				}
			}
		}
	`)

	const { safe, nho, clean, facebook, instagram } =
		query?.sanitySiteSettings?.social || {}

	const { safeImageFixed } = query?.imageSharp

	return (
		<Row {...rest}>
			<Link {...getLinkPropsFromRawData(clean?._rawData)} rel="noopener noreferrer"> 
				<CleanGym hideSmallText />
				<HiddenText>Rent Senter</HiddenText>
			</Link>
			<Link {...getLinkPropsFromRawData(safe?._rawData)} rel="noopener noreferrer">
				<Image fixed={safeImageFixed} alt="Trygt Treningssenter" />
			</Link>
			<Small>
				<Link {...getLinkPropsFromRawData(nho?._rawData)} rel="noopener noreferrer">
					<NHO width="48px" height="38px" />
					<HiddenText>NHO</HiddenText>
				</Link>
				<Link {...getLinkPropsFromRawData(facebook?._rawData)} rel="noopener noreferrer">
					<Facebook />
					<HiddenText>Facebook</HiddenText>
				</Link>
				<Link {...getLinkPropsFromRawData(instagram?._rawData)} rel="noopener noreferrer">
					<Instagram />
					<HiddenText>Instagram</HiddenText>
				</Link>
			</Small>
		</Row>
	)
}
