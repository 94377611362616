import React from "react"
import styled, { css } from "styled-components"

import { utility } from "style/style-utils"
import { COLOR_WHITE } from "style/colors"
import { COLOR_MAIN } from "../../style/colors"

/* This wrapper is required for react-focus to properly detect the button, for some reason. */
const Wrapper = styled.div`
	position: relative;
	align-self: center;
	width: 50px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	&:only-child {
		margin-left: auto;
	}
	${props =>
		props.hideDesktop &&
		css`
			@media screen and (min-width: 800px) {
				display: none;
			}
		`}/*
	z-index: 1000;
	${props =>
		props.open &&
		css`
			position: fixed;
			right: 31px;
			top: 51px;
		`}*/
`

const Burger = styled.button`
	${utility.button}
	display: block;
	position: relative;
	width: 50px;
	height: 31px;
	padding-top: 3px;
	padding-left: 7px;
/* 	overflow: hidden;
 */	color: ${props => props.color};
	background-color: ${COLOR_MAIN};
	&:focus {
		/* Fallback */
		outline: 2px solid currentColor;
		outline-offset: 4px;
		&:not(:focus-visible) {
			/* Hide on normal click */
			outline: none;
		}
	}
	&:focus-visible {
		/* Show on keyboard focus */
		outline: 2px solid currentColor;
		outline-offset: 4px;
	}
	&:hover {
		opacity: 0.8;
	}
	${props =>
		props.open &&
		css`
			width: 36px;
			height: 34px;
			padding-left: 0;
			padding-top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		`}
`

const Bar = styled.div`
	position: relative;
	width: 36px;
	height: 5px;
	color: inherit;
	background-color: currentColor;
	transform-origin: center;
	transition: 0.2s;
	margin-bottom: 5px;
	${props =>
		props.open &&
		css`
			background-color: ${props.openColor || "currentColor"};
		`}
`

const Center = styled(Bar)`
	${props =>
		props.open &&
		css`
			display: none;
		`}
`

const Top = styled(Bar)`
	left: 3px;
	${props =>
		props.open
			? css`
					position: absolute;
					left: 0;
					margin-bottom: 0;
					border-radius: 5px;
					height: 3px;
					transform: rotate(-45deg);
			  `
			: css``}
`

const Bottom = styled(Bar)`
	left: -3px;
	${props =>
		props.open
			? css`
					position: absolute;
					left: 0;
					margin-bottom: 0;
					border-radius: 5px;
					height: 3px;
					transform: rotate(45deg);
			  `
			: css``}
`

const Text = styled.p`
	${utility.visuallyHidden}
`

export const Hamburger = ({
	open,
	onClick,
	color = COLOR_WHITE,
	openColor,
}) => {
	return (
		<Wrapper open={open} onClick={onClick} color={color}>
			<Burger color={color} open={open}>
				<Text>{open ? "Lukk meny" : "Åpne meny"}</Text>
				<Top open={open} openColor={openColor} />
				<Center open={open} />
				<Bottom open={open} color={color} openColor={openColor} />
			</Burger>
		</Wrapper>
	)
}
