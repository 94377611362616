import React from "react"
import styled from "styled-components"
import { Carousel as BaseCarousel } from "react-responsive-carousel"

import "react-responsive-carousel/lib/styles/carousel.min.css"

import { COLOR_MAIN, COLOR_MAIN_DARK, COLOR_MAIN_LIGHT } from "style/colors"

import { InfoCard } from "components/elements/InfoCard/InfoCard"

const CarouselComponent = styled(BaseCarousel)`
	max-width: 100%;
	@media screen and (min-width: 800px) {
		display: none;
	}
	.slide {
		background: transparent;
		/*max-height: 70vh;
		> * {
			height: 100%;
		}*/
	}
	.carousel-slider {
		display: flex;
		flex-direction: column-reverse;
		.slide {
			display: flex;
			flex-direction: column;
		}
		.control-arrow {
			opacity: 1;
		}
		.control-prev.control-arrow:before {
			border-right-color: ${COLOR_MAIN};
		}
		.control-next.control-arrow:before {
			border-left-color: ${COLOR_MAIN};
		}
		.control-arrow:hover {
			background-color: ${COLOR_MAIN}aa;
		}
		.control-dots {
			position: relative;
			display: flex;
		}
		.dot {
			background-color: ${COLOR_MAIN_LIGHT};
			opacity: 1;
			width: 100px;
			height: 10px;
			border-radius: 0;
			&.selected {
				background-color: ${COLOR_MAIN_DARK};
			}
		}
	}
`

const Desktop = styled.div`
	display: none;
	@media screen and (min-width: 800px) {
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}
	> * {
		flex: 1;
	}

	.info-card {
		img,
		.placeholder {
			min-height: 50vh;
			@media screen and (min-width: 800px) {
				min-height: 390px;
			}
		}
		img {
			border-radius: 6px;
		}
	}
`

export const Gallery = ({ items = [] }) => {
	console.log({ items })

	return (
		<>
			<CarouselComponent
				centerMode
				centerSlidePercentage={80}
				showThumbs={false}
				showStatus={false}
				showIndicators={false}
				showArrows={false}
				labels={{
					item: "Bilde",
				}}
			>
				{items.map(({ _key, title, text, image, link }) => (
					<InfoCard
						key={_key}
						title={title}
						text={text}
						image={image}
						link={link}
					/>
				))}
			</CarouselComponent>
			<Desktop>
				{items.map(({ _key, title, text, image, link }) => (
					<InfoCard
						key={_key}
						title={title}
						text={text}
						image={image}
						link={link}
					/>
				))}
			</Desktop>
		</>
	)
}
