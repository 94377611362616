import styled from "styled-components"

import { STYLE } from "style"

import { textPropHandler } from "./textPropHandler"

export const Text = styled.p`
	${STYLE.TEXT.MOBILE.TEXT}
	color: ${props => props.color || "inherit"};
	@media screen and (min-width: 800px) {
		${STYLE.TEXT.DESKTOP.TEXT}
	}
	${textPropHandler}
`
