import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { STYLE } from "style"
import { getLinkProps } from "linkFunctions"

//import { MenuButton } from "./MenuButton"
import { Button as BaseButton } from "components/interactable/Button"

const Button = styled(BaseButton)`
	padding: 0 8px;
`

const Item = styled.li`
	${STYLE.TEXT.MOBILE.MENU}
	margin-bottom: 18px;
	align-items: stretch;
	&:last-child {
		margin-bottom: 0;
	}
	@media screen and (min-width: 800px) {
	}
`

const Wrapper = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 0;
	padding: 0;
	@media screen and (min-width: 800px) {
		flex-direction: row;
		> ${Item} {
			margin-bottom: 0;
			margin-right: 24px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
`

export const DesktopButtons = () => {
	const {
		settings: { desktop_menu_elements },
	} = useStaticQuery(query)

	//console.log({ desktop_menu_elements })

	return (
		<Wrapper>
			{desktop_menu_elements.map(item => {
				//console.log({ item })
				const {
					_key,
					label,
					/*data: {
						options: { linkExternal, linkInternal },
					},*/
					_rawData,
				} = item || {}
				const { linkExternal, linkInternal } = _rawData || {}
				//console.log({ label, linkInternal, linkExternal })

				return (
					<Item key={_key}>
						<Button {...getLinkProps(linkInternal, linkExternal)}>
							{label}
						</Button>
					</Item>
				)
			})}
		</Wrapper>
	)
}

const query = graphql`
	{
		settings: sanitySiteSettings {
			desktop_menu_elements {
				_key
				label
				data {
					options {
						linkExternal {
							href
							blank
						}
						linkInternal {
							reference {
								... on SanityTeam {
									id
									slug {
										current
									}
								}
								... on SanityPage {
									id
									slug {
										current
									}
								}
								... on SanityPackages {
									id
									slug {
										current
									}
								}
								... on SanityOrder {
									id
									slug {
										current
									}
								}
								... on SanityChildPage {
									id
									slug {
										current
									}
								}
								... on SanityPost {
									id
									slug {
										current
									}
								}
							}
						}
					}
					_type
				}
				_rawData(resolveReferences: { maxDepth: 10 })
			}
		}
	}
`
