import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

import { Title } from "components/text/Title"
//import { Text } from "components/text/Text"
import { NextButton } from "components/interactable/NextButton"
import { ContentPortableText } from "components/blockContent/ContentPortableText"
import { getLinkPropsFromRawData } from "../../../linkFunctions"
import { Button } from "../../interactable/Button"

const sanityConfig = { projectId: "x5o90l5y", dataset: "production" }

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 800px) {
		flex-direction: row;
		${props => props.imageOnRight && "flex-direction: row-reverse;"}
		gap: 10px;
	}
`

const Image = styled(Img)`
	flex: 1;
	margin-bottom: 40px;
	border-radius: 6px;
	@media screen and (min-width: 800px) {
		margin-bottom: 0;
		margin-right: 48px;
	}
`

const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media screen and (min-width: 800px) {
		margin-left: 48px;
	}
`

const Links = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	> * {
		flex: 1;
		&:not(:last-child) {
			margin-right: 8px;
		}
	}
`

/**
 * SplitRow component. Displays two columns, one with the image, the other with text.
 * @param {*} param0
 * @returns
 */
export const ArticleCard = ({ title, content, image, links, ...rest }) => {
	console.log({ image, links })
	const fluidProps = image?.image?.asset?.fluid
		? image?.image?.asset?.fluid
		: getFluidGatsbyImage(
				image?.image?.asset?._id,
				{ maxWidth: 1200 },
				sanityConfig
		  )
	return (
		<Wrapper {...rest}>
			<Image fluid={fluidProps} alt={image?.alt} />
			<Content>
				<Title as="h3">{title}</Title>
				<ContentPortableText blocks={content} />
				{links && (
					<Links>
						{links?.map(({ _key, label, data, _rawData }) => {
							console.log({ label, data, _rawData })
							if (links.length === 1) {
								return (
									<NextButton
										key={_key}
										alt={label}
										{...getLinkPropsFromRawData(
											data || _rawData
										)}
									/>
								)
							}
							return (
								<Button
									key={_key}
									{...getLinkPropsFromRawData(
										data || _rawData
									)}
								>
									{label}
								</Button>
							)
						})}
					</Links>
				)}
			</Content>
		</Wrapper>
	)
}
