import { css } from "styled-components"

export const textPropHandler = css`
	${props =>
		props.color &&
		css`
			color: ${props.color};
		`}
	${props =>
		props.center &&
		css`
			text-align: center;
		`}
    ${props =>
		props.nomargin &&
		css`
			margin: 0;
		`}
    ${props =>
		props.mb &&
		css`
			margin-bottom: ${props.mb};
		`}
    ${props =>
		props.bold &&
		css`
			font-weight: bold;
		`}
    ${props =>
		props.left &&
		css`
			text-align: left;
		`}
    ${props =>
		props.center &&
		css`
			text-align: center;
		`}
    ${props =>
		props.right &&
		css`
			text-align: right;
		`}
	@media screen and (min-width: 800px) {
		${props =>
			(props.center || props.dCenter) &&
			css`
				text-align: center;
			`}
		${props =>
			(props.left || props.dLeft) &&
			css`
				text-align: left;
			`}
		${props =>
			(props.right || props.dRight) &&
			css`
				text-align: left;
			`}
		${props =>
			(props.bold || props.dBold) &&
			css`
				font-weight: bold;
			`}
	}
`
